:root {
  --baseblue: #006181;
  --primarycolor: #FFFFFF;
  --gray01: #F3F3F3;
  --bluegray: #CFD8DC;
  --lightgray: #C8C7CB;
  --gray02: #E5E5E8;
  --basegray: #444444;
  --black: #000000;
}

.documentData {
  background: var(--gray02);
  color: var(--baseblue);
  font-size: 11px;
  font-style: italic;
  height: 50px;
  padding: 10px 30px;
  text-align: right;
}

.fhirDocumentData {
  background: var(--gray02);
  color: var(--baseblue);
  font-size: 11px;
  font-style: italic;
  height: 130px;
  padding: 10px 30px;
  text-align: right;
}

.documentTitle {
  font-weight: 600;
  font-size: 12px;
  font-style: initial;
}

.documentViewer {
  background: var(--primarycolor);
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.downloadIcon {
  margin-right: 70px;
  position: absolute;
  right: 0;
}

.errorContainer {
  padding-top: 10%;
  text-align: center;
  font-weight: bold;
  color: red;
}

.centeredProgress {
  padding-top: 10%;
  margin: 0 auto;
  text-align: center;
}

.formControlTypeSelect {
  height: auto;
  width: 98% !important;
  left: 2%;
}

.documentTypesContainer {
  z-index: 1;
  position: relative;
}

.addDocumentButtonContainer {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 5px;
}

.leftButtonContainer {
  z-index: 1;
  left: 16px;
  position: absolute !important;
  transform: translateY(28%);
}

.fhirLeftButtonContainer {
  z-index: 1;
  left: 16px;
  width: 60%;
  position: absolute !important;
  transform: translateY(26%);
}

.freeSpaceButton {
  position: absolute !important;
  transform: translateY(40%);
}

.imgContainer {
  height: 100%;
  padding: 10px;
  text-align: -webkit-center;
  width: 100%;
}

.imgViewer {
  margin: 0;
}

.navButtons,
.navButtonRight,
.navWrapper {
  background: var(--baseblue);
  color: var(--gray01);
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 50px;
  vertical-align: -webkit-baseline-middle;
}

.navButtonRight,
.navButtons {
  border: var(--baseblue);
  padding-top: 6px;
}

.navButtonRight {
  margin-right: 90px;
}

.notAvailableLabel {
  margin: 100px;
}

.patientDetails {
  text-align: left !important;
}

.patientId {
  font-size: 10px;
  font-style: italic;
}

.patientDob {
  color: var(--lightgray);
  font-size: 10px;
}

.pdfViewer {
  text-align: center;
}

.printIcon {
  margin-right: 30px;
  position: absolute;
  right: 0;
}

.rotateLeftIcon {
  margin-right: 150px;
  position: absolute;
  right: 0;
}

.rotateRightIcon {
  transform: scaleX(-1);
  margin-right: 70px;
  position: absolute;
  right: 0;
}

.rotateRightIcons {
  margin-right: 110px;
  position: absolute;
  right: 0;
}

.searchComponent {
  color: var(--baseblue);
  margin-right: 90px;
  padding-left: 5px;
}

.centeredElement {
  text-align: center;
  margin-left: -20px;
}

.iconRow {
  display: flex;
  align-items: center;
}

.curesIcon {
  color: var(--baseblue);
  margin-right: 5px;
  width: 17px !important;
  height: 17px;
}

.typeIcon {
  color: var(--baseblue);
  height: 20px;
  margin-left: auto;
  margin-right: 10%;
  width: 20px;
}

.deleteIcon {
  color: #b2102f;
  height: 18px;
  width: 18px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: 10%;
}

.typeSelect {
  /*height: 19px;*/
  left: 20px;
  position: absolute;
  /*top: 16px;*/
  top: 66px;
  width: 75%;
  z-index: 1;
}

.typeSelectLabel {
  color: var(--baseblue);
  font-size: 12px;
  left: 15px;
  opacity: 0.4;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.viewer {
  text-align: center;
}

.xmlViewer {
  color: var(--black);
  font-size: 12px;
  text-align: -webkit-auto;
}

.xsltViewButton {
  margin-right: 9%;
  position: absolute;
  right: 0;
  top: 152px;
  cursor: pointer;
}

.xsltLabel {
  background-color: var(--baseblue);
  font-size: initial;
  text-decoration: none !important;
}

.test {
  background: var(--baseblue);
  height: 50px;
}

.zoomButtons {
  color: var(--gray01);
  height: 25px;
  width: 25px;
}

.zoomTools {
  left: 20px;
  position: absolute;
  top: 165px;
}

.errorMessage {
  font-size: 1.3rem;
  margin-top: 50px;
}

.navActions {
  height: 50px;
  background: var(--baseblue);
}

.pdfDoc {
  margin-left: 25%;
}

.documentContainer {
  height: calc(100vh - 310px);
  overflow: hidden;
}

.appVersion {
  font-family: Roboto, sans-serif;
  font-size: 8pt;
  position: absolute;
  right: 10px;
}

.appVersionTitle {
  margin-right: 0.5em;
  color: red;
}

.appVersionBranch {
  color: greenyellow;
}

.appVersionHash {
  font-family: "Roboto Mono", monospace;
}

.clientVersion {
  top: 5px;
}

.serverVersion {
  bottom: 3px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.buttonGroup button{
  margin: 0 10px;
}

.curesContainer{
  position: relative;
}

.customTooltipData{
  padding: 0 5px;
}

.customTooltipData hr{
  background-color: var(--lightgray);
  height: 1px;
  border: none;
}

.periodSelector {
  background-color: var(--gray01);
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  left: 0;
  transform: translate(20%, 17px);
  font-size: 12px;
  padding: 5px 10px;
  width: 250px;
  color: #9c9c9c;
}

.calendarIcon{
  color: #7e7e7e;
  margin-right: 5px;
  width: 17px !important;
  height: 17px;
  cursor: pointer;
}

.curesRegistration{
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 400px;
}

.curesRegistration > div{
  margin-top: 10px;
}

.curesUpdateButton{
  position: absolute;
  bottom: 10px;
  background-color: #e9e6e6;
  border-radius: 10px;
  left: 25px;
}

.curesUpdateButton button{
  font-weight: bold;
  font-size: 0.8rem;
}

.curesError{
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  color: red;
}
